#simpleOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.75);
  overflow: hidden;
}

#simpleOverlay p {
  color: #fff;
  font-size: 2rem;
  height: 2rem;
  margin-top: calc(50vh - 2rem);
  text-align: center;
}
